<template>
  <ListContainerWidget
    id="streaming"
    :title="widgetName"
    :app-id="45"
    stretch
    :hiddenActions="true"
    :view-more-location="getLocation('streaming')"
    class="d-flex streaming-widget"
    body-class="streaming-body"
    title-class="streaming-buttons"
  >
    <template #actions>
      <!-- Panel actions -->
      <ViewAllButton item-type="streaming" />
    </template>

    <div style="height: 100%">
      <v-select
        v-if="items != null && items.length > 1"
        name="classifier-filter"
        :clearable="false"
        label="name"
        v-model="name"
        :reduce="(a) => a"
        :options="items"
        class="mb-2"
        @input="setSelected"
      />
      <b-embed
        v-if="selectedOption && !isHTML"
        ref="iframe"
        type="iframe"
        style="height: 80% !important"
        :src="selectedOption"
        v-bind="streamingSettings"
      />
      <div
        id="frameForExternals"
        style="width: 100%; height: 100%; position: relative; display: none"
      >
      </div>
      <div v-if="!selectedOption" class="streaming">
        <img :src="placeholderStreaming" class="p-3" />
        <p>{{ $t("notStarter.message") }}</p>
      </div>
    </div>
  </ListContainerWidget>
</template>

<script>
import WidgetLayoutMixin from "@/@core/mixins/widgets/WidgetLayoutMixin";
import StreamingPlaceholder from "@/assets/images/placeholders/light/streaming.svg";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import ListContainerWidget from "@core/widgets/ListContainerWidget.vue";
import vSelect from 'vue-select';

const IFrameAttributes = Object.freeze([
  "allow",
  "allowfullscreen",
  "allowpaymentrequest",
  "csp",
  "height",
  "loading",
  "name",
  "referrerpolicy",
  "sandbox",
  "src",
  "srcdoc",
  "width",
  // deprecated attributes:
  "align",
  "frameborder",
  "longdesc",
  "marginheight",
  "marginwidth",
  "scrolling",
]);

export default {
  name: 'StreamingWidget',
  components: {
    ViewAllButton: () => import ("@/@core/components/widget-actions/ViewAllButton.vue"  /* webpackChunkName: "ViewAllButton" */),
    ListContainerWidget,
    vSelect
  },
  mixins: [WidgetLayoutMixin],
  props: {
    large: Boolean,
  },
  data() {
    return {
      selectedOption: null,
      name: null,
      isHTML: null,
      windowWidth: window.innerWidth,
      placeholderStreaming: StreamingPlaceholder,
    };
  },
  computed: {
    items() {
      let item = this.$store.getters.iframes.data;
      if (item != null) {
        for (let row in item) {
          item[row].name = this.translateTextOption(item[row].name);
        }
      }
      return item || [];
    },
    hasMoreStreamings() {
      return this.items != null && this.items.length > 1;
    },
    streamingSettings() {
      const streamingSelected = this.items.find(
        (item) => item?.src === this.selectedOption
      );
      const settings = Object.entries(streamingSelected?.settings || {}).reduce(
        (filteredSettings, [key, value]) => {
          if (IFrameAttributes.includes(key)) {
            return {
              ...filteredSettings,
              [key]: value,
            };
          }

          return filteredSettings;
        },
        {}
      );

      const defaultSettings = { allow: "fullscreen" };
      return { ...defaultSettings, ...settings };
    },
  },
  async created() {
    await this.getInitialData();
    this.selectedOption = this.items[0];
    this.name = this.items[0]?.name;
    this.setSelected(this.items[0]);
    document.addEventListener("fullscreenchange", this.handleFullscreenChange);
  },
  beforeDestroy() {
    document.removeEventListener(
      "fullscreenchange",
      this.handleFullscreenChange
    );
  },
  methods: {
    setSelected(item) {
      const deleteFrame = document.getElementById("scrp");
      if (deleteFrame != null) {
        deleteFrame.remove();
      }
      const main = document.getElementById("frameForExternals");
      if (main != null) {
        main.innerHTML = "";
        const frame = document.createElement("div");
        frame.setAttribute("id", "svp_playeraq2hz7x0kh4o");
        main.appendChild(frame);
        if (item?.html != null) {
          this.isHTML = true;
          this.selectedOption = item.html;
          const frame = document.createElement("script");
          frame.setAttribute("id", "scrp");
          frame.innerText = item.html;
          document.body.appendChild(frame);
          document.getElementById("frameForExternals").style.display = "block";
          document.getElementById("svp_playeraq2hz7x0kh4o").style.display =
            "block";
          document.getElementById("svp_playeraq2hz7x0kh4o").style.paddingTop =
            "0px !important";
        } else {
          document.getElementById("svp_playeraq2hz7x0kh4o").style.display =
            "none";
          document.getElementById("frameForExternals").style.display = "none";
          this.isHTML = false;
          this.selectedOption = item?.src;
        }
      }
    },
    async getInitialData() {
      await this.$store.dispatch("getItemsNode", {
        itemType: "iframes",
        page: 1,
        perPage: 16,
      });
      if (this.items?.length > 0) {
        this.selectedOption = this.items[0]?.src;
      }
    },
    handleFullscreenChange(event) {
      if (
        event.srcElement ===
          this.$refs.iframe?.getElementsByTagName("iframe")[0] &&
        !document.fullscreenElement // document.fullscreenElement is only present when in fullscreen mode.
      ) {
        this.$el.scrollIntoView({ block: "center" });
      }
    },
    translateTextOption(name) {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        name
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-include";

.streaming-widget::v-deep {
  .streaming-body {
    display: block;
    margin-bottom: 100px;
    height: 100%;
  }
  .streaming-buttons {
    display: flex;
    justify-content: space-between;
    padding-right: 0;
  }
  .streaming-select {
    position: absolute;
    right: 20px;
    margin-top: 5px;
  }
}
</style>
